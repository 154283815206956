
import { Component, Vue, toNative, Prop } from 'vue-facing-decorator';
import { Root } from '@/main';
import {Tabs, Tab} from 'vue3-tabs-component';
import { TabViewModel } from '@/entities/TabViewModel';
import { createDeviceDetector } from 'next-vue-device-detector';
@Component({
  components: {
    Tabs, Tab,
  },
})
class VueTabs extends Vue {
  @Prop() tabViewModel!: TabViewModel[];
  private localTabViewModel: TabViewModel[] = [];
  private isMounted = false;
  private showNavigationBtns = false;
  private leftNavigationIcon = require('@/assets/Images/left-arrow.svg');
  private rightNavigationIcon = require('@/assets/Images/right-arrow.svg');
  private showLeftNavigationIcon = false; // Check to display left navigation icon
  private showRightNavigationIcon = true; // Check to display right navigation icon
  private device = createDeviceDetector();
  private imageSize = 0;
  private mounted() {
    this.localTabViewModel = this.tabViewModel;
    this.isMounted = true;
    // Add event listener only if device type is desktop
    if(!this.IsDeviceTypeMobileTabOrIpad()) {
      // Adding event listner to detect any change in screen size
      window.addEventListener('resize', this.HandleScreenResize);
    }

    Root.on('select-previous-tab', (val: string) => {
      if(this.$refs.testTabs !== null && this.$refs.testTabs !== undefined) {
        (this.$refs.testTabs as any).selectTab('#'+ val);
      }
    });
  }
  // Check type of device
  private IsDeviceTypeMobileTabOrIpad() {
    return this.device.mobile || this.device.android || this.device.ipad;
  }
  // This is used to check change in screen size
  private HandleScreenResize() {
    this.ShowNavigationButtons();
  }
  // Updated life cycle hook
  private updated() {
    // Add event listener only if device type is desktop
    if(!this.IsDeviceTypeMobileTabOrIpad()) {
      this.ShowNavigationButtons();
    }
  }
  private handleTabChange(tab: any){
    this.$emit('SelectedTab',tab);
  }

  // This is used to scroll the tabs in the direction corresponding to the btn clicked
  private ScrollTabs(direction: any) {
    const container = this.$el.querySelector('.tabs-component-tabs');
    const scrollAmount = 500; // Adjust scroll amount as needed
    if (direction === 'left') {
      this.imageSize = 0;
      container.scrollBy({ left: -scrollAmount, behavior: 'smooth' });
    } else if (direction === 'right') {
      this.imageSize = 36;
      container.scrollBy({ left: scrollAmount, behavior: 'smooth' });
    }
    container.addEventListener('scroll', ()=> setTimeout(this.CheckScrollerPosition , 200));
  }
  // Check current position of scroller such that corresponding buttons can be shown
  private CheckScrollerPosition() {
    const container = this.$el.querySelector('.tabs-component-tabs');
    // Scroller is at begning
    if(container.scrollLeft === 0) {
      this.showLeftNavigationIcon = false;
      this.showRightNavigationIcon = true;
    // Scroller is at end
    } else if(Math.ceil((container.scrollLeft + container.offsetWidth + this.imageSize) as number) >= container.scrollWidth) {
      this.showLeftNavigationIcon =  true;
      this.showRightNavigationIcon = false;
    // Scroller is in between
    } else {
      this.showLeftNavigationIcon = true;
      this.showRightNavigationIcon = true;
    }
  }
  // This is used to show/hide the navigation button
  private ShowNavigationButtons() {
    const container = this.$el.querySelector('.tabs-component-tabs');
    // Check if there is content to scroll
    this.showNavigationBtns = container.scrollWidth > container.clientWidth;
    // Function call to show desired button for navigation
    this.CheckScrollerPosition();
  }
}
export default toNative(VueTabs);
