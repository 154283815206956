import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, Teleport as _Teleport, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, resolveDynamicComponent as _resolveDynamicComponent, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "tabs-container" }
const _hoisted_3 = {
  id: "dvLeftNavigationBtn",
  class: "navigation-btn-container left-navigation-btn-container"
}
const _hoisted_4 = ["src"]
const _hoisted_5 = {
  id: "dvRightNavigationBtn",
  class: "navigation-btn-container right-navigation-btn-container"
}
const _hoisted_6 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tab = _resolveComponent("tab")!
  const _component_tabs = _resolveComponent("tabs", true)!

  return (_ctx.isMounted)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          (_ctx.showNavigationBtns)
            ? (_openBlock(), _createBlock(_Teleport, {
                key: 0,
                to: ".tabs-component-tabs"
              }, [
                _createElementVNode("div", _hoisted_3, [
                  (_ctx.showLeftNavigationIcon)
                    ? (_openBlock(), _createElementBlock("img", {
                        key: 0,
                        src: _ctx.leftNavigationIcon,
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.ScrollTabs('left')))
                      }, null, 8, _hoisted_4))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_5, [
                  (_ctx.showRightNavigationIcon)
                    ? (_openBlock(), _createElementBlock("img", {
                        key: 0,
                        src: _ctx.rightNavigationIcon,
                        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.ScrollTabs('right')))
                      }, null, 8, _hoisted_6))
                    : _createCommentVNode("", true)
                ])
              ]))
            : _createCommentVNode("", true),
          _createElementVNode("div", null, [
            _createVNode(_component_tabs, {
              ref: "testTabs",
              "cache-lifetime": "0",
              options: { useUrlFragment: false },
              onChanged: _ctx.handleTabChange
            }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.localTabViewModel, (item) => {
                  return (_openBlock(), _createBlock(_component_tab, {
                    id: item.Header,
                    key: item.Id,
                    "is-disabled": item.IsDisabled,
                    name: item.Header
                  }, {
                    default: _withCtx(() => [
                      (_openBlock(), _createBlock(_resolveDynamicComponent(item.ComponentName)))
                    ]),
                    _: 2
                  }, 1032, ["id", "is-disabled", "name"]))
                }), 128))
              ]),
              _: 1
            }, 8, ["onChanged"])
          ])
        ])
      ]))
    : _createCommentVNode("", true)
}